<template>
    <div class="new-strategy-page" v-if="client">
        <div class="ns-page-header">
            <div class="prtf-page-header-title-holder">
                <div class="prtf-page-header-maintitle">
                    <div class="prtf-page-header-name">Новая стратегия</div>
                </div>
                <div class="prtf-page-header-subtitle">
                    <ClientHeaderLink :client="client" />
                    <div>01.01.2021</div>
                </div>
            </div>

            <div class="prtf-page-header-buttons">
                <div class="prtf-page-header-button">
                    <el-button>Button</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClientHeaderLink from '@/components/PageComponents/ClientHeaderLink'

export default {
    name: 'client_new_strategy',

    components:{
        ClientHeaderLink
    },

    props: {
        clientid: {
            required: true,
        },
    },

    data(){
        return {
            client : undefined,
        }
    },

    mounted(){
        if (this.clientid) {
            this.$store.dispatch('clients/getClient', this.clientid).then((client_response) => {
                this.client = client_response
                this.$emit('tab-title-change', 'New Strategy for ' + this.client.first_name + ' ' + this.client.last_name)
            })
        }
    },
}
</script>
