<template>
    <div v-if="clientid">
        <div v-if="strategyid">Strategy Page</div>
        <div v-else><NewStrategy :clientid="clientid" @tab-title-change="tab_title_change" /></div>
    </div>
</template>

<script>
import NewStrategy from './NewStrategy'

export default {
    components: {
        NewStrategy
    },

    props: {
        clientid: {
            required: true,
        },
        strategyid: {
            required: false,
        },
    },

    methods: {
        tab_title_change(title){
            this.$emit('tab-title-change', title)
        }
    },
}
</script>
